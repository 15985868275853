<template>
  <div id="a-edit-project">
    <div>
      <!-- IMAGE CONTAINER -->
      <div>
        <img
          :class="displayImage != null ? 'a-card-img' : 'a-default-card-img'"
          :src="
            displayImage != null
              ? displayImage
              : require('@/assets/images/public/image.svg')
          "
        />

        <FileUpload
          mode="basic"
          name="demo[]"
          accept="image/*"
          :maxFileSize="2000000"
          @select="onImageUpload"
          :chooseLabel="'Editar'"
        />
      </div>

      <!-- SLOT FOR MAP COMPONENT -->
      <!-- IT HAS TO BE RENDERED OUTSIDE A DIALOG COMPONENT -->
      <slot></slot>

      <!-- MULTISELECT FOR BUILDERS -->
      <Multiselect
        v-model="localItem.builders"
        mode="multiple"
        placeholder="Constructoras"
        :options="builders"
        label="name"
        :searchable="true"
        :noResultsText="'No hay resultados'"
        :noOptionsText="'La lista está vacía'"
        valueProp="id"
        trackBy="name"
      >
        <template v-slot:multiplelabel="">
          <div class="multiselect-multiple-label">Constructoras</div>
        </template>
      </Multiselect>

      <!-- CONTAINER FOR SELECTED BUILDERS NAME CARDS -->
      <div id="a-builders-container">
        <div
          v-for="(builder, index) in originalBuilders"
          :key="builder.builder.id"
          :class="
            index > 4 && hidden
              ? 'a-hidden a-selected-builder'
              : 'a-selected-builder'
          "
          v-tooltip.bottom="builder.builder.name"
          @click="deleteBuilder(builder)"
        >
          <span>{{ builder.builder.name }}</span>
        </div>
        <div
          class="a-selected-builder"
          id="a-builder-button"
          @click="hidden = !hidden"
          v-if="originalBuilders != null && originalBuilders.length > 5"
        >
          <span v-if="hidden">Ver más</span>
          <span v-else>Ver menos</span>
        </div>
      </div>
      <hr />

      <div id="a-builders-container">
        <div
          v-for="(builder, index) in newBuilders"
          :key="builder.id"
          :class="
            index > 4 && hidden
              ? 'a-hidden a-selected-builder'
              : 'a-selected-builder'
          "
          v-tooltip.bottom="builder.name"
          @click="unselectBuilder(builder)"
        >
          <span>{{ builder.name }}</span>
        </div>
        <div
          class="a-selected-builder"
          id="a-builder-button"
          @click="hidden = !hidden"
          v-if="newBuilders != null && newBuilders.length > 5"
        >
          <span v-if="hidden">Ver más</span>
          <span v-else>Ver menos</span>
        </div>
      </div>
    </div>

    <div>
      <InputText
        type="text"
        v-model="localItem.name"
        placeholder="Nombre del proyecto*"
      />
      <Textarea
        v-model="localItem.description"
        rows="2"
        cols="30"
        placeholder="Descripción"
      />
      <InputText
        type="text"
        v-model="localItem.codeAnt"
        placeholder="Cod. Agencia Nacional de Tierras *"
      />
      <InputText
        type="text"
        v-model="localItem.address"
        placeholder="Dirección"
      />
      <InputText
        type="text"
        v-model="localItem.latitude"
        placeholder="Latitud *"
      />
      <InputText
        type="text"
        v-model="localItem.longitude"
        placeholder="Longitud *"
      />
      <InputText
        type="text"
        v-model="localItem.housingUnits"
        placeholder="Unidades de vivienda"
      />

      <!-- CONTAINER FOR STATES OPTIONS -->
      <div id="a-states-main-container">
        <div id="a-states-container">
          <div
            class="a-states"
            v-for="(type, index) in types"
            :key="index"
            :style="`background: #${type.color}`"
            :id="
              localItem.projectType.id == type.id
                ? 'a-selected'
                : 'a-unselected'
            "
            v-tooltip.bottom="type.name"
            @click="localItem.projectType = type"
          ></div>
        </div>
        <!-- STATE SELECTED -->
        <div
          id="a-state-selected"
          :style="`background: #${localItem.projectType.color}`"
        >
          {{ localItem.projectType.name }}
        </div>
      </div>
    </div>
  </div>

  <div class="a-buttons">
    <Button
      label="Cancelar"
      class="p-button-raised p-button-danger p-button-text"
      @click="cancel"
    />
    <button
      :class="!isLoading ? 'a-main-btn' : 'a-main-btn a-loading-btn'"
      :disabled="isLoading || !isValid"
      @click="editProject"
    >
      Aceptar
    </button>
  </div>
  <Toast />
</template>

<script>
import UserAPI from "@/services/users.js";
import BuilderAPI from "@/services/builders.js";
import ProjectAPI from "@/services/projects.js";
import ProjectTypeAPI from "@/services/projecttypes.js";
import Multiselect from "@vueform/multiselect";
export default {
  name: "EditProject",
  props: {
    item: Object,
  },
  components: {
    Multiselect,
  },
  data() {
    return {
      localItem: {
        name: "",
        codeAnt: "",
        address: "",
        housingUnits: "",
        latitude: "",
        longitude: "",
        ruta_imagen: "",
        projectType: "",
        description: "",
        builders: null,
      },
      isLoading: false,
      builders: [],
      types: [],
      hidden: true,
    };
  },
  computed: {
    isValid() {
      return (
        this.localItem.name != "" &&
        this.localItem.codeAnt != "" &&
        this.localItem.latitude != "" &&
        this.localItem.longitude != ""
      );
    },
    newBuilders() {
      if (this.localItem.builders) {
        return this.builders.filter((i) =>
          this.localItem.builders.includes(i.id)
        );
      } else {
        return null;
      }
    },
    displayImage() {
      if (typeof this.localItem.ruta_imagen == "string") {
        return this.localItem.ruta_imagen;
      } else if (
        typeof this.localItem.ruta_imagen == "object" &&
        this.localItem.ruta_imagen != null
      ) {
        return URL.createObjectURL(this.localItem.ruta_imagen);
      } else {
        return null;
      }
    },
    originalBuilders() {
      let original = [];
      this.item.builderProject.forEach((element) => {
        original.push({
          builder: element.idBuilder,
          referenceId: element.idBuilderProject,
        });
      });
      return original;
    },
  },
  emits: ["onSuccess", "onExit", "onBuilderDeleted"],
  methods: {
    cancel() {
      this.$emit("onExit");
    },
    onImageUpload(event) {
      //event.files == files to upload
      this.localItem.ruta_imagen = event.files[0];
    },

    editProject() {
      this.isLoading = true;
      if (
        typeof this.localItem.ruta_imagen == "string" ||
        this.localItem.ruta_imagen == null
      ) {
        delete this.localItem.ruta_imagen;
      }
      this.localItem.projectType = this.localItem.projectType.id;

      var form_data = new FormData();
      for (var key in this.localItem) {
        form_data.append(key, this.localItem[key]);
      }

      ProjectAPI.update(this.item.id, form_data)
        .then((response) => {
          if (this.localItem.builders != null) {
            this.localItem.builders.forEach((builder) => {
              ProjectAPI.createBuilder({
                idProject: response.data.id,
                idBuilder: builder,
              });
            });
          }
          this.$emit("onSuccess");
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response) {
            let response = error.response.data;
            let errors = Object.keys(response);
            let message = "";
            let isTokenError = false;
            errors.forEach((err) => {
              message += response[err];
              if (err == "code") {
                if (response[err] == "token_not_valid") {
                  isTokenError = true;
                  UserAPI.updateToken().then((response) => {
                    sessionStorage.setItem("cfta", response.data.access);
                    this.editProject();
                  });
                }
              }
            });
            if (!isTokenError) {
              this.$toast.add({
                severity: "error",
                summary: "Ha ocurrido un error",
                detail: message,
                life: 3000,
              });
            }
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Ha ocurrido un error",
              detail: error.message,
              life: 3000,
            });
          }
        });
    },
    getBuilders() {
      BuilderAPI.index()
        .then((response) => {
          this.builders = response.data;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response) {
            let response = error.response.data;
            let errors = Object.keys(response);
            let message = "";
            let isTokenError = false;
            errors.forEach((err) => {
              message += response[err];
              if (err == "code") {
                if (response[err] == "token_not_valid") {
                  isTokenError = true;
                  UserAPI.updateToken().then((response) => {
                    sessionStorage.setItem("cfta", response.data.access);
                    this.getBuilders();
                  });
                }
              }
            });
            if (!isTokenError) {
              this.$toast.add({
                severity: "error",
                summary: "Ha ocurrido un error",
                detail: message,
                life: 3000,
              });
            }
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Ha ocurrido un error",
              detail: error.message,
              life: 3000,
            });
          }
        });
    },
    getProjectTypes() {
      ProjectTypeAPI.index()
        .then((response) => {
          this.types = response.data;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response) {
            let response = error.response.data;
            let errors = Object.keys(response);
            let message = "";
            let isTokenError = false;
            errors.forEach((err) => {
              message += response[err];
              if (err == "code") {
                if (response[err] == "token_not_valid") {
                  isTokenError = true;
                  UserAPI.updateToken().then((response) => {
                    sessionStorage.setItem("cfta", response.data.access);
                    this.getProjectTypes();
                  });
                }
              }
            });
            if (!isTokenError) {
              this.$toast.add({
                severity: "error",
                summary: "Ha ocurrido un error",
                detail: message,
                life: 3000,
              });
            }
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Ha ocurrido un error",
              detail: error.message,
              life: 3000,
            });
          }
        });
    },
    unselectBuilder(builder) {
      this.localItem.builders.forEach((project, index) => {
        if (project == builder.id) {
          this.localItem.builders.splice(index, 1);
        }
      });
    },
    deleteBuilder(builder) {
      ProjectAPI.deleteBuilder(builder.referenceId)
        .then(() => {
          this.$emit("onBuilderDeleted");
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response) {
            let response = error.response.data;
            let errors = Object.keys(response);
            let message = "";
            let isTokenError = false;
            errors.forEach((err) => {
              message += response[err];
              if (err == "code") {
                if (response[err] == "token_not_valid") {
                  isTokenError = true;
                  UserAPI.updateToken().then((response) => {
                    sessionStorage.setItem("cfta", response.data.access);
                    this.deleteBuilder(builder);
                  });
                }
              }
            });
            if (!isTokenError) {
              this.$toast.add({
                severity: "error",
                summary: "Ha ocurrido un error",
                detail: message,
                life: 3000,
              });
            }
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Ha ocurrido un error",
              detail: error.message,
              life: 3000,
            });
          }
        });
    },
  },
  mounted() {
    this.getBuilders();
    this.getProjectTypes();

    this.localItem = this.item;
    this.localItem.builders = null;
  },
};
</script>

<style>
.p-dialog .p-dialog-content .p-inputtext {
  margin-bottom: 2px;
}

.p-fileupload-basic {
  position: relative;
  margin: -45px 0 20px 10px;
}

.p-fileupload .p-button,
.p-fileupload .p-button:hover {
  color: white;
  background: var(--text-color);
  min-width: 67px;
  width: 67px;
  max-height: 28px;
  min-height: 28px;
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 500;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-button .p-button-icon-left {
  display: none;
}

.vue-map-container {
  height: 120px !important;
}
</style>

<style scoped>
#a-edit-project {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;
}

#a-states-main-container {
  display: flex;
  align-items: space-between;
  margin: 5px 0;
}
#a-states-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  height: 45px;
  padding: 0 15px;
  margin-right: 10px;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.04);
}
.a-states {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin: 0 5px;
  transition: 0.5s;
}

.a-states:hover {
  width: 30px;
  height: 30px;
  border: 2px solid white;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
  cursor: pointer;
}
#a-state-selected {
  width: 100%;
  height: 45px;
  border-radius: 15px;
  color: white;
  border: 2px white solid;
  display: flex;
  justify-content: center;
  align-items: center;
}

.a-selected-builder {
  background: white;
  height: 30px;
  padding: 8px;
  margin: 10px 0;
  border-radius: 6px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: -0.1px;
  text-align: left;
  cursor: pointer;
}

.a-selected-builder:hover {
  background: #f9d4df;
}

#a-builders-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(6em, 1fr));
  column-gap: 10px;
}

#a-builders-container .a-hidden {
  display: none;
}

#a-builder-button {
  cursor: pointer;
  color: var(--color-2);
  font-weight: 600;
  text-decoration: underline;
}
</style>

<style src="@vueform/multiselect/themes/default.css"></style>
