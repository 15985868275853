import { render } from "./EditProject.vue?vue&type=template&id=0156b4fc&scoped=true"
import script from "./EditProject.vue?vue&type=script&lang=js"
export * from "./EditProject.vue?vue&type=script&lang=js"

import "./EditProject.vue?vue&type=style&index=0&id=0156b4fc&lang=css"
import "./EditProject.vue?vue&type=style&index=1&id=0156b4fc&scoped=true&lang=css"
import "@vueform/multiselect/themes/default.css?vue&type=style&index=2&lang=css"
script.render = render
script.__scopeId = "data-v-0156b4fc"

export default script