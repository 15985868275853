<template>
  <div id="a-add-project">
    <div>
      <FileUpload
        name="demo[]"
        @select="onImageUpload"
        accept="image/*"
        :maxFileSize="2000000"
        :showUploadButton="false"
        :showCancelButton="false"
        :chooseLabel="'Selecciona el archivo jpg/png'"
      >
        <template #empty>
          <p>o arrastra y suelta aquí.</p>
        </template>
      </FileUpload>

      <Map :markers="mapConfig.markers" />

      <Multiselect
        v-model="project.builders"
        mode="multiple"
        placeholder="Constructoras"
        :options="builders"
        label="name"
        :searchable="true"
        :noResultsText="'No hay resultados'"
        :noOptionsText="'La lista está vacía'"
        valueProp="id"
        trackBy="name"
      >
        <template v-slot:multiplelabel="">
          <div class="multiselect-multiple-label">Constructoras</div>
        </template>
      </Multiselect>

      <div id="a-builders-container">
        <div
          v-for="(builder, index) in selectedBuilders"
          :key="builder.id"
          :class="
            index > 4 && hidden
              ? 'a-hidden a-selected-builder'
              : 'a-selected-builder'
          "
          v-tooltip.bottom="builder.name"
          @click="unselectBuilder(builder)"
        >
          <span>{{ builder.name }}</span>
        </div>
        <div
          class="a-selected-builder"
          id="a-builder-button"
          @click="hidden = !hidden"
          v-if="selectedBuilders != null && selectedBuilders.length > 5"
        >
          <span v-if="hidden">Ver más</span>
          <span v-else>Ver menos</span>
        </div>
      </div>
    </div>
    <div>
      <InputText
        type="text"
        v-model="project.name"
        placeholder="Nombre del proyecto*"
      />
      <Textarea
        v-model="project.description"
        :autoResize="true"
        rows="5"
        cols="30"
        placeholder="Descripción"
      />
      <InputText
        type="text"
        v-model="project.codeAnt"
        placeholder="Cod. Agencia Nacional de Tierras *"
      />
      <InputText
        type="text"
        v-model="project.address"
        placeholder="Dirección"
      />
      <InputText
        type="text"
        v-model="project.latitude"
        placeholder="Latitud *"
      />
      <InputText
        type="text"
        v-model="project.longitude"
        placeholder="Longitud *"
      />
      <InputText
        type="text"
        v-model="project.housingUnits"
        placeholder="Unidades de vivienda"
      />
      <div id="a-states-main-container">
        <div id="a-states-container">
          <div
            class="a-states"
            v-for="(type, index) in types"
            :key="index"
            :style="`background: #${type.color}`"
            :id="
              project.projectType.id == type.id ? 'a-selected' : 'a-unselected'
            "
            v-tooltip.bottom="type.name"
            @click="project.projectType = type"
          ></div>
        </div>
        <div
          id="a-state-selected"
          :style="`background: #${project.projectType.color}`"
        >
          {{ project.projectType.name }}
        </div>
      </div>
    </div>
  </div>

  <div class="a-buttons">
    <Button
      label="Cancelar"
      class="p-button-raised p-button-danger p-button-text"
      @click="cancel"
    />
    <button
      :class="!isLoading ? 'a-main-btn' : 'a-main-btn a-loading-btn'"
      :disabled="isLoading || !isValid"
      @click="newProject"
    >
      Aceptar
    </button>
  </div>
  <Toast />
</template>

<script>
import UserAPI from "@/services/users.js";
import BuilderAPI from "@/services/builders.js";
import ProjectAPI from "@/services/projects.js";
import ProjectTypeAPI from "@/services/projecttypes.js";
import Map from "@/components/Maps/Map.vue";
import Multiselect from "@vueform/multiselect";
export default {
  name: "NewProject",
  components: {
    Map,
    Multiselect,
  },
  data() {
    return {
      project: {
        name: "",
        codeAnt: "",
        address: "",
        housingUnits: "",
        latitude: "",
        longitude: "",
        ruta_imagen: "",
        projectType: "",
        description: "",
        builders: null,
      },
      isLoading: false,
      builders: [],
      types: [],
      mapConfig: {
        markers: [],
      },
      hidden: true,
    };
  },
  computed: {
    isValid() {
      return (
        this.project.name != "" &&
        this.project.codeAnt != "" &&
        this.project.latitude != "" &&
        this.project.longitude != "" &&
        this.project.builders != null
      );
    },
    selectedBuilders() {
      if (this.project.builders) {
        return this.builders.filter((i) =>
          this.project.builders.includes(i.id)
        );
      } else {
        return null;
      }
    },
  },
  emits: ["onSuccess", "onExit"],
  methods: {
    cancel() {
      this.$emit("onExit");
    },
    onImageUpload(event) {
      //event.files == files to upload
      this.project.ruta_imagen = event.files[0];
    },

    newProject() {
      this.isLoading = true;

      this.project.projectType = this.project.projectType.id;

      var form_data = new FormData();
      for (var key in this.project) {
        form_data.append(key, this.project[key]);
      }

      ProjectAPI.create(form_data)
        .then((response) => {
          this.project.builders.forEach((builder) => {
            ProjectAPI.createBuilder({
              idProject: response.data.id,
              idBuilder: builder,
            });
          });
          this.$emit("onSuccess");
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response) {
            let response = error.response.data;
            let errors = Object.keys(response);
            let message = "";
            let isTokenError = false;
            errors.forEach((err) => {
              message += response[err];
              if (err == "code") {
                if (response[err] == "token_not_valid") {
                  isTokenError = true;
                  UserAPI.updateToken().then((response) => {
                    sessionStorage.setItem("cfta", response.data.access);
                    this.newProject();
                  });
                }
              }
            });
            if (!isTokenError) {
              this.$toast.add({
                severity: "error",
                summary: "Ha ocurrido un error",
                detail: message,
                life: 3000,
              });
            }
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Ha ocurrido un error",
              detail: error.message,
              life: 3000,
            });
          }
        });
    },
    getBuilders() {
      BuilderAPI.index()
        .then((response) => {
          this.builders = response.data;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response) {
            let response = error.response.data;
            let errors = Object.keys(response);
            let message = "";
            let isTokenError = false;
            errors.forEach((err) => {
              message += response[err];
              if (err == "code") {
                if (response[err] == "token_not_valid") {
                  isTokenError = true;
                  UserAPI.updateToken().then((response) => {
                    sessionStorage.setItem("cfta", response.data.access);
                    this.getBuilders();
                  });
                }
              }
            });
            if (!isTokenError) {
              this.$toast.add({
                severity: "error",
                summary: "Ha ocurrido un error",
                detail: message,
                life: 3000,
              });
            }
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Ha ocurrido un error",
              detail: error.message,
              life: 3000,
            });
          }
        });
    },
    getProjectTypes() {
      ProjectTypeAPI.indexActive()
        .then((response) => {
          this.types = response.data;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response) {
            let response = error.response.data;
            let errors = Object.keys(response);
            let message = "";
            let isTokenError = false;
            errors.forEach((err) => {
              message += response[err];
              if (err == "code") {
                if (response[err] == "token_not_valid") {
                  isTokenError = true;
                  UserAPI.updateToken().then((response) => {
                    sessionStorage.setItem("cfta", response.data.access);
                    this.getProjectTypes();
                  });
                }
              }
            });
            if (!isTokenError) {
              this.$toast.add({
                severity: "error",
                summary: "Ha ocurrido un error",
                detail: message,
                life: 3000,
              });
            }
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Ha ocurrido un error",
              detail: error.message,
              life: 3000,
            });
          }
        });
    },
    unselectBuilder(builder) {
      this.project.builders.forEach((project, index) => {
        if (project == builder.id) {
          this.project.builders.splice(index, 1);
        }
      });
    },
  },
  mounted() {
    this.getBuilders();
    this.getProjectTypes();
  },
};
</script>

<style>
#a-add-project .p-dialog .p-dialog-content .p-inputtext {
  margin-bottom: 2px;
}
#a-add-project .p-fileupload .p-fileupload-content {
  padding: 2rem 1rem;

  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border: none;
  height: 150px;
  color: var(--text-color);
  margin: 0 0 5px 0;
}
#a-add-project .p-fileupload .p-fileupload-buttonbar {
  margin-top: 5px;
  border: none;
  color: transparent;
  background: transparent;
  padding: 0;
  border-radius: 0;
}
#a-add-project .pi-fw {
  display: none;
}
#a-add-project .p-fileupload .p-button,
#a-add-project .p-fileupload .p-button:hover {
  color: var(--text-color);
  background: white;
  width: 100%;
  min-width: 100%;
  font-size: 16px;
  font-weight: 500;
  padding: 20px;
}
#a-add-project .p-fileupload .p-fileupload-buttonbar .p-button {
  margin: 0 !important;
  border-radius: 10px 10px 0 0 !important;
}
#a-add-project .p-fileupload-filename {
  display: none;
}

#a-add-project .vue-map-container {
  height: 120px !important;
}
</style>

<style scoped>
#a-add-project {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 40px;
}

#a-states-main-container {
  display: flex;
  align-items: space-between;
  margin: 5px 0;
}
#a-states-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  height: 45px;
  padding: 0 15px;
  margin-right: 10px;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.04);
}
.a-states {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin: 0 5px;
  transition: 0.5s;
}

.a-states:hover {
  width: 30px;
  height: 30px;
  border: 2px solid white;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
  cursor: pointer;
}
#a-state-selected {
  width: 100%;
  height: 45px;
  border-radius: 15px;
  color: white;
  border: 2px white solid;
  display: flex;
  justify-content: center;
  align-items: center;
}

.a-selected-builder {
  background: white;
  height: 30px;
  padding: 8px;
  margin: 10px 0;
  border-radius: 6px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-family: Montserrat;
  font-size: 10px;
  font-weight: 500;
  letter-spacing: -0.1px;
  text-align: left;
  cursor: pointer;
}

.a-selected-builder:hover {
  background: #f9d4df;
}

#a-builders-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(6em, 1fr));
  column-gap: 10px;
}

#a-builders-container .a-hidden {
  display: none;
}

#a-builder-button {
  cursor: pointer;
  color: var(--color-2);
  font-weight: 600;
  text-decoration: underline;
}
</style>

<style src="@vueform/multiselect/themes/default.css"></style>
