import { render } from "./NewProject.vue?vue&type=template&id=79feb720&scoped=true"
import script from "./NewProject.vue?vue&type=script&lang=js"
export * from "./NewProject.vue?vue&type=script&lang=js"

import "./NewProject.vue?vue&type=style&index=0&id=79feb720&lang=css"
import "./NewProject.vue?vue&type=style&index=1&id=79feb720&scoped=true&lang=css"
import "@vueform/multiselect/themes/default.css?vue&type=style&index=2&lang=css"
script.render = render
script.__scopeId = "data-v-79feb720"

export default script