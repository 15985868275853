<template>
  <Menubar />
  <MainContainer>
    <template v-slot:header>
      <button class="a-main-btn" @click="settings.addModal = true">
        Agregar
      </button>
      <div class="a-search-container">
        <InputText placeholder="Buscar" v-model="filters['global'].value" />
      </div>
    </template>
    <DataTable
      :value="projects"
      :paginator="true"
      :rows="7"
      paginatorTemplate="PrevPageLink PageLinks NextPageLink"
      :filters="filters"
      :globalFilterFields="['name', 'address']"
    >
      <!-- Id field -->
      <Column field="id" header="Id." sortable headerStyle="width: 5rem" />
      <!-- Fields defined in data section -->
      <Column
        v-for="col of fields"
        :field="col.field"
        :header="col.header"
        :key="col.field"
        sortable
      />

      <!-- State field -->
      <Column header="Condición" headerStyle="width: 9rem">
        <template #body="slotProps">
          <div id="a-table-switch">
            <InputSwitch
              v-model="slotProps.data.active"
              @change="onStateChange(slotProps.data)"
              :disabled="usertype.name != 'Administrador'"
            />
          </div>
        </template>
      </Column>

      <!-- Action buttons -->
      <Column headerStyle="width: 9rem">
        <template #body="slotProps">
          <div class="a-container-tab-btns">
            <button
              class="a-icon-tab-btn a-detail-btn"
              @click="detailProject(slotProps.data)"
            >
              <img :src="require('@/assets/icons/eye-white.svg')" />
            </button>
            <button
              class="a-icon-tab-btn a-edit-btn"
              @click="editProject(slotProps.data)"
            >
              <img :src="require('@/assets/icons/tool-white.svg')" />
            </button>
          </div>
        </template>
      </Column>
    </DataTable>
  </MainContainer>

  <Dialog
    header="Añadir proyecto"
    v-model:visible="settings.addModal"
    v-if="settings.addModal"
    :modal="true"
    :breakpoints="{ '960px': '75vw' }"
    :style="{ width: '80vw' }"
  >
    <NewProject @onSuccess="projectAdded" @onExit="settings.addModal = false" />
  </Dialog>

  <Dialog
    v-model:visible="settings.editModal"
    v-if="settings.editModal"
    :modal="true"
    :breakpoints="{ '960px': '75vw' }"
    :style="{ width: '80vw' }"
  >
    <template #header>
      <h3>
        Proyecto <span class="a-id-badge">{{ settings.project.id }}</span>
      </h3>
    </template>
    <EditProject
      :item="settings.project"
      @onSuccess="projectEdited"
      @onExit="settings.editModal = false"
      @onBuilderDeleted="onBuilderDeleted()"
    >
      <Map
        :isMarkerClickable="false"
        :markers="[
          {
            position: {
              lat: parseFloat(settings.project.latitude.replace(',', '.')),
              lng: parseFloat(settings.project.longitude.replace(',', '.')),
            },
            icon: 'undefined',
            project: settings.project,
          },
        ]"
      />
    </EditProject>
  </Dialog>

  <Dialog
    v-model:visible="settings.detailModal"
    v-if="settings.detailModal"
    :modal="true"
    :breakpoints="{ '960px': '90vw' }"
    :style="{ width: '75vw' }"
  >
    <template #header>
      <h3>
        Proyecto <span class="a-id-badge">{{ settings.project.id }}</span>
      </h3>
    </template>
    <DetailProject :item="settings.project">
      <Map
        :isMarkerClickable="false"
        :markers="[
          {
            position: {
              lat: parseFloat(settings.project.latitude.replace(',', '.')),
              lng: parseFloat(settings.project.longitude.replace(',', '.')),
            },
            icon: 'undefined',
            project: settings.project,
          },
        ]"
      />
    </DetailProject>
  </Dialog>

  <ConfirmDialog />

  <Toast />
</template>

<script>
import Menubar from "@/components/navigation/Top.vue";
import MainContainer from "@/components/secure/MainContainer.vue";
import NewProject from "@/components/projects/NewProject.vue";
import ProjectAPI from "@/services/projects.js";
import UserAPI from "@/services/users.js";
import { FilterMatchMode } from "primevue/api";
import DetailProject from "@/components/projects/PublicDetailProject.vue";
import EditProject from "@/components/projects/EditProject.vue";
import Map from "@/components/Maps/Map.vue";

export default {
  name: "Projects",
  components: {
    Menubar,
    MainContainer,
    NewProject,
    DetailProject,
    Map,
    EditProject,
  },
  data() {
    return {
      projects: [],
      fields: [
        {
          field: "name",
          header: "Nombre",
        },
        {
          field: "address",
          header: "Dirección",
        },
      ],
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      settings: {
        addModal: false,
        detailModal: false,
        editModal: false,
        project: null,
      },
    };
  },
  computed: {
    usertype() {
      if (
        this.$store.state.user != null &&
        this.$store.state.user.userType != null
      ) {
        return this.$store.state.user.userType;
      } else {
        return "Editor";
      }
    },
  },
  methods: {
    getProjects() {
      ProjectAPI.index()
        .then((response) => {
          this.projects = response.data;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response) {
            let response = error.response.data;
            let errors = Object.keys(response);
            let message = "";
            let isTokenError = false;
            errors.forEach((err) => {
              message += response[err];
              if (err == "code") {
                if (response[err] == "token_not_valid") {
                  isTokenError = true;
                  UserAPI.updateToken().then((response) => {
                    sessionStorage.setItem("cfta", response.data.access);
                    this.getProjects();
                  });
                }
              }
            });
            if (!isTokenError) {
              this.$toast.add({
                severity: "error",
                summary: "Ha ocurrido un error",
                detail: message,
                life: 3000,
              });
            }
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Ha ocurrido un error",
              detail: error.message,
              life: 3000,
            });
          }
        });
    },
    projectAdded() {
      this.settings.addModal = false;
      this.getProjects();
      this.$toast.add({
        severity: "success",
        detail: "Se ha añadido un nuevo proyecto",
        life: 3000,
      });
    },
    editProject(item) {
      this.settings.project = item;
      this.settings.editModal = true;
    },
    onBuilderDeleted() {
      this.getProjects();
      this.$toast.add({
        severity: "success",
        detail: "Se ha eliminado la constructora del proyecto",
        life: 3000,
      });
      this.settings.project = null;
      this.settings.editModal = false;
    },
    onStateChange(project) {
      ProjectAPI.updateState(project)
        .then(() => {
          this.$toast.add({
            severity: "success",
            detail: project.active
              ? "El proyecto ha sido publicado"
              : "Se retiró la publicación del proyecto",
            life: 3000,
          });
          this.getProjects();
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response) {
            let response = error.response.data;
            let errors = Object.keys(response);
            let message = "";
            let isTokenError = false;
            errors.forEach((err) => {
              message += response[err];
              if (err == "code") {
                if (response[err] == "token_not_valid") {
                  isTokenError = true;
                  UserAPI.updateToken().then((response) => {
                    sessionStorage.setItem("cfta", response.data.access);
                    this.onStateChange(project);
                  });
                }
              }
            });
            if (!isTokenError) {
              this.$toast.add({
                severity: "error",
                summary: "Ha ocurrido un error",
                detail: message,
                life: 3000,
              });
            }
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Ha ocurrido un error",
              detail: error.message,
              life: 3000,
            });
          }
        });
    },
    projectEdited() {
      this.settings.editModal = false;
      this.settings.project = null;
      this.getProjects();
      this.$toast.add({
        severity: "success",
        detail: "Se ha editado un nuevo proyecto",
        life: 3000,
      });
    },
    detailProject(item) {
      this.settings.project = item;
      this.settings.detailModal = true;
    },
  },
  mounted() {
    this.getProjects();
  },
};
</script>

<style>
#a-table-switch .p-inputswitch {
  width: 60px;
}

#a-table-switch .p-inputswitch .p-inputswitch-slider {
  height: 26px;
}

#a-table-switch
  .p-inputswitch.p-inputswitch-checked
  .p-inputswitch-slider:before {
  transform: translateX(30px);
}
#a-table-switch .p-inputswitch .p-inputswitch-slider:before {
  width: 20px;
  height: 20px;
  margin-top: -0.625rem;
  left: 5px;
}
</style>
